import {
  handleQueryResolve
} from '../utils'

export default function (patientID, rxNumber, genericName, brandName) {
  if (patientID) {
    const attrs = [{
      param: 'PatientID',
      type: 'sql.Int',
      value: patientID, // to support like
    }]
    const where = ['PatientID = @PatientID']

    if (rxNumber) {
      attrs.push({
        param: 'rxNumber',
        type: 'sql.VarChar(50)',
        value: `%${rxNumber}%`, // to support like
      })

      where.push('RXNumber LIKE @rxNumber')
    }

    if (genericName) {
      attrs.push({
        param: 'genericName',
        type: 'sql.VarChar(50)',
        value: `%${genericName}%`, // to support like
      })

      where.push('GenericName LIKE @genericName')
    }

    if (brandName) {
      attrs.push({
        param: 'brandName',
        type: 'sql.VarChar(50)',
        value: `%${brandName}%`, // to support like
      })

      where.push('BrandName LIKE @brandName')
    }

    return this.query(`
    SELECT "DBA"."Prescription"."RxNumber" AS "RxNumber",
    "DBA"."Prescription"."FillDate" AS "RxDate",
    "DBA"."Prescription"."InstructionText" AS "SIGFull",
    "DBA"."Prescription"."FillQuantity" AS "RxQtyDispense",
    "DBA"."Drug"."GenericName" AS "GenericName",
    "DBA"."Drug"."DIN" AS "DIN",
    "DBA"."Drug"."TradeName" AS "BrandName",
    "DBA"."Drug"."Strength" AS "RxStrength",
    "DBA"."Prescription"."PatientId" AS "PatientID",
    "DBA"."Doctor"."LastName",
    "DBA"."Prescription"."DaysSupply" AS "Days",
    "DBA"."Doctor"."FirstName"
    FROM ( ( "DBA"."Prescription" JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id" ) JOIN "DBA"."DrugForm" ON "DBA"."Drug"."FormCode" = "DBA"."DrugForm"."Code" ) JOIN "DBA"."Doctor" ON "DBA"."Prescription"."DoctorId" = "DBA"."Doctor"."Id"
    AND FillDate > DATEADD( month,  -1, GetDate() ) AND ${where.join(' AND ')}
    ORDER BY "RxDate" DESC
        `, attrs).then(value => {
      const values = value.map(data => {
        const modifiedData = { ...data }
        modifiedData.Days = Math.trunc(parseInt(data.Days))

        return modifiedData
      })

      return values
    })
      .then(handleQueryResolve)
  }
}
